import React, { useState } from 'react';
import axios from 'axios';
import './Chatbot.css';

const Chatbot = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);

const chatWithGenAI = async (userInput) => {
    // const apiEndpoint = 'https://api.npms.io/v2/search?q=axios';
    // const apiEndpoint = 'https://yeub7w3ew2.execute-api.us-east-1.amazonaws.com/dev/helloworld';
    const apiEndpoint= 'https://hggh8ax862.execute-api.us-east-1.amazonaws.com/dev/taxkb';
    const headers = {
      'Content-Type': 'application/json',
      // 'Authorization': `Bearer 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`,
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Credentials': true
      'x-api-key': 'E0a5npbmfVa511qAXnQSha4HoRXJUyQ05nvhSYEM'
    };


    const data = {
      prompt: userInput,
      max_tokens: 15000
    };
try {
      // const  response = await axios.get(apiEndpoint, { headers });

      // const element = document.querySelector('#post-request-set-headers .body');
      // const question = {"question": "What is the difference between market discount and qualified stated interest?"};
      const question = {"question": { userInput }};
      const response = await axios.post('https://hggh8ax862.execute-api.us-east-1.amazonaws.com/dev/taxkb', question,  { headers });

      return response.data.body;
    } catch (error) {
      console.error('Error communicating with the API:', error.message);
      return '';
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    const userMessage = { text: input, user: true };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    const aiMessage = { text: '...', user: false };
    setMessages((prevMessages) => [...prevMessages, aiMessage]);
    const response = await chatWithGenAI(input);
    const newAiMessage = { text: response, user: false };
    setMessages((prevMessages) => [...prevMessages.slice(0, -1), newAiMessage]);
    setInput('');
  };
  return (


    <div className="chatbot-container">
    <img src={require('./bot.png')} style={{height:40, width:40}}/>
    <img src={require('./images/ipath.png')} style={{height:60, width:150}} class="center"/>
    <div className="header-display">

    <b>Welcome to Gen AI TaxBot</b>

    </div>
      <div className="chatbot-messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.user ? 'user-message' : 'ai-message'}`}
          >
            {message.text}
          </div>
        ))}
      </div>
      <form className="chatbot-input-form" onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your Question..."
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};
export default Chatbot;
