import React from 'react';
import Chatbot from './Chatbot';

function App() {
  return (
    <div>
      <h1>GenAI Tax Chatbot!!!</h1>
      <Chatbot />
    </div>
  );
}
export default App;
